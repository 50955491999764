/* TOP BANNER */

.banner-container {
  width: 100%;
  height: 80vh;
  background: url("../../assets/astronaut-vibing.jpg");
  background-size: cover;
  background-position: 80% 90%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

@media (min-width: 1280px) {
  .banner-container {
    background-position: 0% 10%;
  }
}

.banner-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.banner-text {
  color: white;
  font-size: 1.5rem;
  max-width: 320px;
  margin-top: 2em;
  align-self: center;
}

@media (min-width: 576px) {
  .banner-text {
    align-self: flex-start;
    margin-left: 1em;
    font-size: 2.1rem;
    max-width: 520px;
  }
}
@media (min-width: 770px) {
  .banner-content {
    width: 80%;
  }
}

/* POSTS AND NEWS */

.content-container {
  background-color: #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.post-container,
.news-container {
  display: none;
}

.post-container {
  margin-bottom: 2em;
}

.post-wrapper{
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 576px) {
  .post-container,
  .news-container {
    padding: 0 1.875em;
    width: 99.5%;
  }
}

@media (min-width: 770px) {
  .post-container,
  .news-container {
    width: 80%;
  }
}

.news-container {
  background-color: white;
  width: 100%;
  padding: 1.375em 1.875em;
}

.news-container > h1 {
  margin-bottom: 0.75em;
}

.news-article {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.5em;
  border-bottom: 1px solid #bbbbbb;
  padding-bottom: 0.75em;
  margin-top: 1em;
}

.news-content {
  width: 65%;
}

.news-title {
  font-size: 0.93rem;
  margin: 0.75em 0;
}

.news-summary {
  font-size: 0.625rem;
  margin-bottom: 0.75em;
}

.news-website {
  font-size: 0.93rem;
  color: #02b9f2;
  text-decoration: none;
}

.news-img {
  width: 101px;
  height: 125px;
  background-color: #00ffff;
  border-radius: 0.65em;
  align-self: center;
  overflow: hidden;
}

.news-img img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.active-container {
  display: flex;
  flex-direction: column;
}

.mobile-tabs {
  position: sticky;
  bottom: 0;
  background-color: #030204;
  display: flex;
  justify-content: space-around;
  padding: .5em 1em;
  transform: translateY(100%);
  transition: all 0.3s;
}

.mobile-tabs-active {
  transform: translateY(0%);
}

.tab-icon {
  cursor: pointer;
  padding: 0.5em;
  border-radius: .65em;
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.tab-icon:hover {
  background-color: #d9d9d9;
  color: #030204;
}

.active-tab {
  padding: 0.5em;
  background-color: #d9d9d9;
  color: black;
}

/* ON DESKTOP SCREENS */

@media (min-width: 992px) {
  .mobile-tabs {
    display: none;
  }

  .post-container,
  .news-container {
    display: flex;
    max-width: 700px;
  }


  .news-container {
    align-self: flex-start;
    position: sticky;
    top: 100px;
    margin-top: 1.5em;
    width: 55%;
    height: 80vh;
    flex-direction: column;
    align-items: center;
  }

  .news-wrapper {
    overflow-y: auto;
  }

  .news-article {
    padding-right: 1em;
  }

  .news-summary {
    font-size: 0.75rem;
  }

  .content-flex {
    width: 100%;
    background-color: #d9d9d9;
  }

  .content-container {
    flex-direction: row;
    width: 80%;
    margin: 0 auto;
    justify-content: space-around;
  }
}

@media (min-width: 1200px) {
  .news-summary {
    font-size: 0.93rem;
  }
}
