.nav-container {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #030204;
  display: flex;
  justify-content: center;
}

.navbar {
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .7em 1.875em;
}

@media (min-width: 770px) {
  .navbar {
    width: 80%;
  }
}

.logo {
  font-weight: 400;
  cursor: pointer;
}

.logo a,
.nav-links a,
.sidebar li a {
  color: white;
}

.GiHamburgerMenu {
  cursor: pointer;
}

.nav-links {
  display: none;
}

.sidebar {
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.9);
  right: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 50%;
  transform: translateX(100%);
  transition: all 0.4s;
  z-index: 999;
}

.sidebar li {
  list-style: none;
  padding: 1em 1.8em;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.openSidebar {
  transform: translateX(0%);
}

.closeSidebar {
  align-self: flex-end;
  margin-top: 1.1em;
  margin-right: 1.8em;
  cursor: pointer;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  background: black;
  display: none;
}

.showBackdrop {
  display: block;
}

@media (min-width: 992px) {
  .GiHamburgerMenu {
    display: none;
  }

  .nav-links {
    display: flex;
  }

  .nav-links li {
    list-style: none;
    margin-left: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
  }
}
