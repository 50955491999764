.error-container{
    text-align: center;
}

.img-error-container{
    margin-top: 5em;
    width: 100%;
    height: 50vh;
}

.img-error-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.error-container a {
    color: blue;
}