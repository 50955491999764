.single-post-container {
  background-color: white;
}

.post-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.562);
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-post,
.comment {
  display: flex;
  flex-direction: column;
}

.single-post {
  margin-top: 1.5em;
  padding: 1.375em 1.875em;
  /* border-bottom: 1px solid #acabab; */
}

.post-img-container {
  border-radius: 0.75em;
  width: 100%;
  height: 18.75em;
  background: #9d9797;
  margin-bottom: 1.25em;
  overflow: hidden;
}

.post-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.single-post::after {
  content: "";
  width: 100%;
  margin-top: 1.375em;
  height: 1px;
  background-color: #9d9797;
}

.post-interactions {
  display: flex;
  justify-content: space-between;
}

.post-likes,
.post-user-info {
  display: flex;
  align-items: center;
}

.post-like-amount {
  font-size: 0.75rem;
  color: #9d9797;
  margin-left: 1em;
}

.post-username {
  font-size: 1.3rem;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: .2em;
}

.post-username:hover{
  color: aqua;
}

.post-posted-date {
  font-size: 0.75rem;
  margin-left: 2em;
  color: #9d9797;
}

.post-comment-text {
  padding: 1em 0;
}

.post-edit,
.post-delete{
  padding: .6em 1em;
  margin-right: 1em;
  font-weight: bold;
  background: none;
  cursor: pointer;
  transition: all .2s ease-out;
}

.post-edit{
  border: 2px solid #53BA33;
  color: #53BA33;
}

.post-edit:hover{
  background: #53BA33;
  color: black;
}

.post-delete{
  border: 2px solid #ff0000;
  color: #ff0000;
}

.post-delete:hover{
  background: #ff0000;
  color: black;
}

@media (min-width: 576px) {
  .single-post {
    padding: 1.8em 3em;
  }
}

@media (min-width: 770px) {
  .single-post-container {
    width: 80%;
    max-width: 850px;
    margin: 0 auto;
  }

  .post-img-container {
    width: 80%;
    max-width: 550px;
    margin: 1em 0;
  }
}
