.card-post {
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;
  padding: 1.375em 1.875em;

}

.card-content,
.like-icon{
  cursor: pointer;
}

@media (min-width: 576px) {
  .card-post {
    padding: 1.8em 3em;
  }
}

.card-likes,
.card-comments,
.card-user-info {
  display: flex;
  align-items: center;
}

.card-like-amount,
.card-comment-amount {
  font-size: 0.6875rem;
  color: #9d9797;
  margin-left: 1em;
}

.card-username {
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: .2em;
}

.card-username:hover{
  color: aqua;
}

.card-posted-date {
  font-size: 0.75rem;
  margin-left: 2em;
  color: #9d9797;
}

.card-comments{
  cursor: pointer;
}

.card-comment-text {
  padding: 1em 0;
}

.card-img-container {
  border-radius: 0.75em;
  width: 100%;
  height: 18.75em;
  background: #9d9797;
  margin-bottom: 1.25em;
  overflow: hidden;
}

.card-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-interactions {
  display: flex;
  justify-content: space-between;
}
