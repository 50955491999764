.signup-page {
  width: 100%;
  height: 100vh;
  background: url("../../assets/purple-galaxy-v2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 
.signupform-container {
  margin: 5em auto;
} */

.signupform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(217, 217, 217, 0.8);
  padding: 1.25em 2em;
  border-radius: 0.68em;
  margin: 0 1.2em;
}

.signup-title {
  font-size: 1rem;
  padding: 1em;
}

.form-error{
  color: red;
  padding: .75em;
  border: 1px solid red;
  margin-bottom: 1em;
}

@media (min-width: 576px){
    .signup-title{
        font-size: 1.25rem;
    }
}

.signupform input {
  width: 100%;
  padding: 0.9em;
  background: #ffffff;
  border-radius: 0.5em;
  border: none;
  margin-bottom: 1em;
}

.signupform span {
  color: red;
  align-self: flex-start;
}

.signupform h3 {
  font-size: 1rem;
  margin: 0.5em 0;
}

.signupform h3 a {
  color: #b57eeb;
}

.signupform h3 a:hover {
  text-decoration: underline;
  color: #ff8a00;
}

.SignUpBtn {
  width: 100%;
  padding: 0.9em;
  background-color: #b57eeb;
  border-radius: 0.5em;
  border: none;
  margin: 1.25em 0;
  cursor: pointer;
  color: #ffffff;
  transition: all .2s;
}

.SignUpBtn:hover{
    color: #b57eeb;
    background: rgba(217, 217, 217, 0.8);
}
